import { combineReducers } from "redux";
import { actionTypes } from './actions'
import { HYDRATE } from 'next-redux-wrapper'
import { META, Logo, SEARCH_BY_KEY } from '@/lib/constants'
import { merge, orderBy } from 'lodash';

const initialState = {
  isOpenSelectSeatModal: false,
  locationList: [],
  isLoading: false,
  spaces: undefined,
  cities: [],
  industry: [],
  zoneList: [],
  qrcodeStr: '',
  registerInfo: null,
  purchaseInfo: null,
  bookingInfo: null,
  userToken: null,
  tradeNo: null,
  selectedSeat: null,
  selectedCode: null,
  guestVerification: {
    payload: {},
    form: {
      status: '',
      isCheckAgreement: false,
    },
    token: ''
  },
  reservation: {
    lastName: null,
    firstName: null,
    companyType: null,
    companyName: null,
    companyPhone: null,
  },
  trial: {
    lastName: null,
    firstName: null,
    contactPhone: null,
    gender: null,
    livingArea: null,
    industry: null,
    selectedSeat: null,
    selectedCode: null,
    location: null,
    agreement: false,
    date: '',
    time: '',
  },
  searchResults: [{
    name: '忠孝復興',
    location: 'location',
    cover: '/banner/Photo_03@2x.png',
    availablePeople: 200,
    price: 350,
    unit: '小時',
    remainingSeat: 10,
    geo: {
      lat: 25.041693695588783,
      lng: 121.54381490834052,
    }
  }, {
    name: '大安',
    location: 'location',
    cover: '/banner/Photo_03@2x.png',
    availablePeople: 200,
    price: 350,
    unit: '小時',
    remainingSeat: 10,
    geo: {
      lat: 25.03298397479801,
      lng: 121.54355741628487,
    }
  }, {
    name: '信義安和',
    location: 'location',
    cover: '/banner/Photo_03@2x.png',
    availablePeople: 200,
    price: 350,
    unit: '小時',
    remainingSeat: 10,
    geo: {
      lat: 25.03380053739284,
      lng: 121.55295587631687,
    }
  }]
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload }
    }

    case actionTypes.OPEN_SELECT_SEAT_MODAL:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ isOpenSelectSeatModal: true },
      }

    case actionTypes.CLOSE_SELECT_SEAT_MODAL:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ isOpenSelectSeatModal: false, selectedSeat: action.payload.selectedSeat, selectedCode: action.payload.selectedCode, spaces: [] },
      }

    case actionTypes.FETCH_SEARCH_RESULT:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ searchResults: state.searchResults },
      }


    case actionTypes.SUBMIT_RESERVATION:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ reservation: { ...action.payload } },
      }

    case actionTypes.UPDATE_FREE_TRIAL:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ trial: { ...state.trial, ...action.payload } },
      }

    case actionTypes.FETCH_LOCATION_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      console.log(action.payload)
      const formattedLocation = action.payload.map(location => merge(location, {
        name: location.name,
        location: 'location',
        cover: '/banner/location/chingcheng.jpg',
        availablePeople: 200,
        price: 350,
        unit: '小時',
        note: '捷運南京復興站 1 號出口',
        businessHour: `${location.start_time} - ${location.end_time}`,
        remainingSeat: 10,
        address: location.address,
        // geo:{
        //   lat: 25.03380053739284,
        //   lng: 121.55295587631687,
        // },
        geo: {
          lat: location.latitude,
          lng: location.longitude,
        }
      }))
      console.log(formattedLocation)
      return {
        ...state,
        locationList: [...formattedLocation],
      }
    case actionTypes.FETCH_SPACE_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      // console.log(action.payload.data.length)
      const listObj = SEARCH_BY_KEY
      // console.log('params')
      // console.log(action.params)
      // console.log(listObj[action.params.zone_id].length)
      const listFromSearchByKey = Object.values(listObj).reduce((a, v) => a.concat(v), [])
      // console.log(listFromSearchByKey)
      // console.log('listFromSearchByKey')
      // location_id 141
      const formattedSpaces = action.payload.data.map(space => {
        console.log(space)
        if (action.params.zone_id == 2) {
          // 透過 location_id mapping 前端寫死的資料
          const result = listFromSearchByKey.filter((draft) => space.location_id === draft.location_id)
          if (result) {
            // console.log(result[0])
            // 重組一個由後端與前端組合的物件。為什麼有這個情況？原因是後端給的跟使用者想要的不一致，沒有理解使用者需要變成要前端去組做中間的介接。space為後端/result為前端
            return ({
              id: space.location_name,
              name: space.class_description,
              location: space.location_name,
              cover: result[0].img,
              // availablePeople: space.room_limit,
              availablePeople: result[0].available,
              // price: 350,
              unit: '小時',
              note: '捷運南京復興站 1 號出口',
              businessHour: `${space.start_time} - ${space.end_time}`,
              remainingSeat: action.params.zone_id == 2 ? space.class_schedule_reserve_quantity : null,
              address: space.address,
              // geo:{
              //   lat: 25.03380053739284,
              //   lng: 121.55295587631687,
              // },
              // 從後端來的樣式是暗掉的
              // dataSource: 'backend',
              // 從前端來的樣式是亮的
              dataSource: action.params.zone_id == 2 ? 'backend' : 'user',
              ...result[0],
              name: result[0].area,
              location: result[0].name,
              geo: {
                lat: space.location_latitude,
                lng: space.location_longitude,
              },
            })
          }
        }

        return {
          ...space,
          cover: space.img,
          name: space.area,
          location: space.name,
          availablePeople: space.available,
          remainingSeat: space.remaining,
          geo: space.geo

        }
      })
      console.log(formattedSpaces)
      // 將寫死的組入進api資料
      // const sources = listObj[action.params.zone_id].map(draft => ({
      //   ...draft,
      //   name: draft.name,
      //   location: draft.area,
      //   cover: '/banner/Photo_03@2x.png',
      //   availablePeople: draft.available,
      //   price: draft.price,
      //   unit: '小時',
      //   note: '捷運南京復興站 1 號出口',
      //   // businessHour: `${space.start_time} - ${space.end_time}`,
      //   remainingSeat: draft.remaining || 10,
      //   // remainingSeat: draft.remaining,
      //   // address: space.address,
      //   cta: draft.cta,
      //   dataSource: 'user',
      // })).concat(formattedSpaces)
      // console.log('sources')
      // console.log(sources)

      return {
        ...state,
        spaces: [...formattedSpaces],
        // spaces: [...sources],
      }
    case actionTypes.FETCH_ZONE_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      const filteredZoneList = action.payload
      // const filteredZoneList = action.payload.filter((list) => list.zone_id != 6)
      console.log(filteredZoneList)
      console.log('filteredZoneList')
      return {
        ...state,
        ...{ zoneList: [...filteredZoneList] },
      }
    case actionTypes.FETCH_SPACES_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      // staff_id 是 後臺 uuid, staff_code 則是 user read
      // 如果測試環境尚未提供，則使用 staff_id
      const seats = action.payload.map(seat => ({ ...seat, staff_id: Number(seat.staff_id), staff_code: seat.staff_code ? seat.staff_code : seat.staff_id }))
      const sortedSpaces = orderBy(seats, ['staff_id']);
      console.log(seats)
      console.log('sortedSpaces')
      console.log(sortedSpaces)
      return {
        ...state,
        ...{ spaces: [...(state.spaces ? state.spaces : []), ...sortedSpaces] },
      }
    case actionTypes.FETCH_INDUSTRY_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ industry: [...state.industry, ...action.payload] },
      }
    case actionTypes.FETCH_CITIES_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ cities: [...state.cities, ...action.payload] },
      }

    case actionTypes.FETCH_QRCODE_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ qrcodeStr: action.payload.qrcode },
      }

    case actionTypes.FETCH_REGISTER_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ registerInfo: action.payload, userToken: action.payload.user_token },
      }

    case actionTypes.FETCH_PURCHASE_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ purchaseInfo: action.payload, tradeNo: action.payload.trade_no },
      }

    case actionTypes.FETCH_BOOKING_DATA_RECEIVED:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ bookingInfo: action.payload, registerInfo: null, purchaseInfo: null },
      }

    case actionTypes.SET_LOADING_STATUS:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      return {
        ...state,
        ...{ isLoading: action.payload.isLoading },
      }
    case actionTypes.SET_GUEST_TOKEN:
      console.log(`action ${action.type}: ${JSON.stringify(action.payload)}`)
      const { token } = action.payload;
      return {
        ...state,
        ...{ guestVerification: { token } },
      }

    default:
      return state
  }
}

// export default reducer
const rootReducer = combineReducers({
  reducer,
});

export default rootReducer;
