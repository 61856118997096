import { message, notification } from 'antd';
import { toUrlParamsString } from '@/lib/utils';
import moment from 'moment';
import { DEFAULT_SEARCH_FILTERS, DATETIME_FORMAT, DATE_FORMAT, SEARCH_BY_KEY } from '@/lib/constants'
import { sendRequest } from '@/lib/utils';
// const asyncMiddleware = fn =>
//   (req, res) => {
//     Promise.resolve(fn(req, res))
//       .catch();
//   };

// const asyncMiddleware = fn =>
//   (req, res) => {
//     try {

//     } catch (e) {
//       Promise.resolve(fn(req, res))
//         .catch();
//     }
//   };
const showMessageSeconds = 5
export const actionTypes = {
  HYDRATE: 'HYDRATE',

  OPEN_SELECT_SEAT_MODAL: 'OPEN_SELECT_SEAT_MODAL',
  CLOSE_SELECT_SEAT_MODAL: 'CLOSE_SELECT_SEAT_MODAL',
  UPDATE_SEARCH_RESULT: 'UPDATE_SEARCH_RESULT',
  FETCH_SEARCH_RESULT: 'FETCH_SEARCH_RESULT',

  UPDATE_FREE_TRIAL: 'UPDATE_FREE_TRIAL',
  SUBMIT_RESERVATION: 'SUBMIT_RESERVATION',

  FETCH_LOCATION_DATA_RECEIVED: 'FETCH_LOCATION_DATA_RECEIVED',
  FETCH_SPACE_DATA_RECEIVED: 'FETCH_SPACE_DATA_RECEIVED',
  FETCH_ZONE_DATA_RECEIVED: 'FETCH_ZONE_DATA_RECEIVED',
  FETCH_SPACES_DATA_RECEIVED: 'FETCH_SPACES_DATA_RECEIVED',
  FETCH_INDUSTRY_DATA_RECEIVED: 'FETCH_INDUSTRY_DATA_RECEIVED',
  FETCH_CITIES_DATA_RECEIVED: 'FETCH_CITIES_DATA_RECEIVED',
  FETCH_QRCODE_DATA_RECEIVED: 'FETCH_QRCODE_DATA_RECEIVED',

  FETCH_REGISTER_DATA_RECEIVED: 'FETCH_REGISTER_DATA_RECEIVED',
  FETCH_PURCHASE_DATA_RECEIVED: 'FETCH_PURCHASE_DATA_RECEIVED',
  FETCH_BOOKING_DATA_RECEIVED: 'FETCH_BOOKING_DATA_RECEIVED',

  SET_LOADING_STATUS: 'SET_LOADING_STATUS',
  SET_GUEST_TOKEN: 'SET_GUEST_TOKEN'
}

export const loadData = () => {
  return { type: actionTypes.LOAD_DATA }
}

export const loadSpaces = (data) => {
  return {
    type: actionTypes.UPDATE_SEARCH_RESULT,
    data,
  }
}

export const reSelectSeat = (idx, data) => {
  console.log('reSelectSeat')
  return {
    type: actionTypes.UPDATE_SEARCH_RESULT,
    idx,
    data,
  }
}


// Guest Verification Actions
export const submitGuestVerification = ({
  name = '',
  phone = '',
  code = ''
}) => async (dispatch, getState) => {
  console.log('payload', { name, phone, code })
  const url = toUrlParamsString(`/api/guest_verification`, {
    name,
    phone,
    code,
  });
  dispatch({ type: actionTypes.SET_LOADING_STATUS, payload: { isLoading: true } })
  try {
    const res = await sendRequest(url)
    // console.log(res)
    if (!res.ok) throw new Error(`伺服器忙碌中，請稍後再試。Code: ${res.status}`);
    if (res.code === 1) {
      message.success({ content: "訪客驗證成功", duration: showMessageSeconds });
    } else if (res.code === 2) {
      message.success({ content: res.reason, duration: showMessageSeconds });
    } else {
      message.error({ content: res.reason, duration: showMessageSeconds });
    }
    dispatch({ type: actionTypes.SET_GUEST_TOKEN, payload: { token: res.data.token } })
    // EX: 已經預約過 回應
    // code: 2
    // data: {token: "46b340f56855422395de57cd0a58c88eTPR9T5XI"}
    // token: "46b340f56855422395de57cd0a58c88eTPR9T5XI"
    // reason: "您已經預約過囉"
    // success: "您已經預約過囉"
    // token: "46b340f56855422395de57cd0a58c88eTPR9T5XI"

    // if (res.code == -5) message.error({ content: res.reason, duration: showMessageSeconds });
    // if (res.code == 401) message.error({ content: `你輸入的訪客驗證碼，已達入場上限！`, duration: showMessageSeconds });
    // if (res.code == 402) message.error({ content: `你輸入的訪客驗證碼錯誤，請重新輸入`, duration: showMessageSeconds });
  } catch (e) {
    message.error({ content: e.message, duration: showMessageSeconds });
  }
  dispatch({ type: actionTypes.SET_LOADING_STATUS, payload: { isLoading: false } })
}


export const searchByFilters = ({
  // 必填 zone_id date start_time end_time
  zone_id = 2,
  date = moment().format(DATE_FORMAT),
  start_time = moment().format(DATETIME_FORMAT),
  end_time = moment().add(30, 'minutes').format(DATETIME_FORMAT),
  location_no,
  city_zip,
  lang = 'cht',
  ...params
}) => async (dispatch, getState) => {
  // console.log('searchByFilters')
  // console.log(moment().add(30, 'minutes').format(DATETIME_FORMAT))
  console.log({
    zone_id,
    date,
    start_time,
    end_time,
    location_no,
    city_zip,
    lang,
  })
  const url = toUrlParamsString(`/api/search`, {
    zone_id,
    date,
    start_time,
    end_time,
    location_no,
    city_zip,
    lang,
    // zone_id: 1,
    // date: '2021-09-01',
    // start_time: '08:00',
    // end_time: '23:00'
  });
  dispatch({
    type: actionTypes.SET_LOADING_STATUS,
    payload: { isLoading: true },
  });
  if (zone_id == 2) {
    try {
      const responsePromise = await fetch(`${url}`);
      const json = await responsePromise.json();
      console.log(json)
      if (!!json.error) {
        console.log(json)
        message.error({
          content: `搜尋結果: ${json.message}`,
          duration: showMessageSeconds
        });
        // throw new Error(json)
      } else {
        dispatch({
          type: actionTypes.FETCH_SPACE_DATA_RECEIVED,
          payload: json,
          params: {
            zone_id,
            date,
            start_time,
            end_time,
            location_no,
            city_zip,
            lang,
          }
          // payload: [1, 2, 3]
        });
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    dispatch({
      type: actionTypes.FETCH_SPACE_DATA_RECEIVED,
      payload: { data: SEARCH_BY_KEY[zone_id] },
      params: {
        zone_id,
        date,
        start_time,
        end_time,
        location_no,
        city_zip,
        lang,
      }
      // payload: [1, 2, 3]
    });
  }
  dispatch({
    type: actionTypes.SET_LOADING_STATUS,
    payload: { isLoading: false },
  });
};

export const fetchSpaceById = ({ id = 1 }) => async (dispatch, getState) => {
  const state = getState();
  const url = `https://jsonplaceholder.typicode.com/users/${id}`;

  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    console.log(json)
    dispatch({
      type: actionTypes.FETCH_SPACE_DATA_RECEIVED,
      payload: { json, }
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchLocationList = () => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  const url = `/api/location`;
  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    console.log(json)
    dispatch({
      type: actionTypes.FETCH_LOCATION_DATA_RECEIVED,
      payload: json.data.filter(location => {
        // 後端新設定了 have_free_trial 參數 "1" or "0" 來判斷是否顯示。
        return typeof location.have_free_trial !== "undefined" ? location.have_free_trial === "1" : true;
      })
    });
  } catch (error) {
    console.log(error);
  }
}

export const fetchSpaceStatus = ({ location_no, zone_id, booking_date, booking_time, lang }) => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  console.log({ location_no, zone_id, booking_date, booking_time, lang })
  const url = toUrlParamsString(`/api/spaces`, { location_no, zone_id, booking_date, booking_time });
  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    console.log(json)
    dispatch({
      type: actionTypes.FETCH_SPACES_DATA_RECEIVED,
      payload: json.data
    });
  } catch (error) {
    console.log(error);
  }
}
export const fetchJobIndustry = () => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  const url = `/api/industry`;
  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    console.log(json)
    dispatch({
      type: actionTypes.FETCH_INDUSTRY_DATA_RECEIVED,
      payload: json.data
    });
  } catch (error) {
    console.log(error);
  }
}
export const fetchCities = ({ country_zip, lang }) => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  console.log({ country_zip, lang })
  const url = toUrlParamsString(`/api/cities`, { country_zip });
  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    console.log(json)
    dispatch({
      type: actionTypes.FETCH_CITIES_DATA_RECEIVED,
      payload: json.data
    });
  } catch (error) {
    console.log(error);
  }
}
export const fetchFreeTrailQRcode = ({ user_token }) => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  console.log({ user_token })
  const url = toUrlParamsString(`/api/qrcode`, { user_token });
  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    if (json.error) {
      console.log(json.error)
    } else {
      dispatch({
        type: actionTypes.FETCH_QRCODE_DATA_RECEIVED,
        payload: json.data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export const registerFreeTrial = ({
  email,
  contactPhone: phone,
  livingArea: local,
  lastName: last_name,
  firstName: first_name,
  gender: sex,
  livingArea: city_zip,
  location: location_uuid,
  industry: job_category_code,
  birth: birthday,
  ...params
}) => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  // dev-fitbutler.xarehub.com
  console.log({
    email,
    phone,
    last_name,
    first_name,
    sex,
    city_zip,
    birthday,
    location_uuid,
    job_category_code,
  })
  const url = toUrlParamsString(`/api/register`, {
    phone,
    last_name,
    first_name,
    sex,
    city_zip,
    birthday,
    location_uuid,
    job_category_code,
    email,
  });
  const responsePromise = await fetch(url);
  const json = await responsePromise.json();
  if (json.error) {
    console.log(json)
    message.error({
      content: `註冊失敗: ${json.message}`,
      duration: showMessageSeconds
    });
    throw new Error(json)
  } else {
    dispatch({
      type: actionTypes.FETCH_REGISTER_DATA_RECEIVED,
      payload: json.data
    });
  }
}

export const purchaseFreeTrial = ({
  user_token,
  purchase_location_id,
  package_id = 'xarefree1',
  payment_type = 'local_cash',
  save_token = 'enable',
  available_location_ids,
  coach_id,
  invoice_type = 'invoice_write',
  ...params
}) => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  console.log({
    user_token,
    purchase_location_id,
    package_id,
    payment_type,
    save_token,
    available_location_ids,
    coach_id,
    invoice_type,
  })
  const url = toUrlParamsString(`/api/purchase`, {
    user_token,
    purchase_location_id,
    package_id,
    payment_type,
    save_token,
    available_location_ids,
    coach_id,
    invoice_type,
  });
  const responsePromise = await fetch(url);
  const json = await responsePromise.json();
  if (json.error) {
    console.log(json)
    message.error({
      content: `購買方案失敗: ${json.message}`,
      duration: showMessageSeconds
    });
    throw new Error(json)
  } else {
    dispatch({
      type: actionTypes.FETCH_PURCHASE_DATA_RECEIVED,
      payload: json.data
    });
  }
}

export const bookingFreeTrial = ({
  staff_id,
  user_token,
  trade_no,
  booking_time,
  end_time,
  ...params
}) => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  console.log({
    staff_id,
    user_token,
    trade_no,
    booking_time,
    end_time,
  })
  const url = toUrlParamsString(`/api/book`, {
    staff_id,
    user_token,
    trade_no,
    booking_time,
    end_time,
  });
  const responsePromise = await fetch(url);
  const json = await responsePromise.json();
  dispatch({
    type: actionTypes.FETCH_BOOKING_DATA_RECEIVED,
    payload: json
  });
  if (json.error) {
    console.log(json)
    message.error({
      content: `預訂座位失敗: ${json.message}`,
      duration: showMessageSeconds
    });
    throw new Error(json)
  }
}

export const submitFreeTrail = (payload) => async (dispatch, getState) => {
  // console.log(payload)
  // return
  // 1. register -> get usertoken
  if (payload.contactPhone) {
    try {
      await dispatch(registerFreeTrial({
        ...payload,
        birth: `${moment(payload.birth).format('YYYY-MM-DD')}`
      }))
      console.log('registerFreeTrial end')
      message.success('註冊成功！');
    } catch (e) {
      console.log(e)
      // message.error('註冊失敗: 1');
    }
  }
  // const data = {"error":0,"data":{"phone":"0920200000","first_name":"d","last_name":"d","sex":"female","city_zip":"F","user_token":"9wKSlJTLZBGNcQxgJpwRJkPPa4WSacl5u2o57BXd","location_uuid":"249e59e7-1102-4478-b402-04e84546b708","job_category_code":"startup-company"},"message":"Success"}
  // 2. purchase -> get tradeno
  const { registerInfo = {}, selectedSeat } = getState().reducer;
  console.log(selectedSeat)
  if (registerInfo?.user_token) {
    try {
      await dispatch(purchaseFreeTrial({
        // user_token: "qitCNHUbPh8E5NCkkOarO38i88uLP6iakqKRLbwM",
        user_token: registerInfo.user_token,
        purchase_location_id: registerInfo.location_uuid,
        available_location_ids: registerInfo.location_uuid,
        coach_id: selectedSeat,
      }))
      console.log('purchaseFreeTrial end')
      message.success('購買會籍成功！');
    } catch (e) {
      console.log(e)
      // message.error(`溝買失敗: 2`);
    }
  }
  // TEST: 0920200000 3 會檢查
  // 3. book -> get success
  const { purchaseInfo = {} } = getState().reducer;
  console.log(selectedSeat)
  if (purchaseInfo?.trade_no) {
    try {
      await dispatch(bookingFreeTrial({
        staff_id: selectedSeat,
        user_token: registerInfo.user_token,
        trade_no: purchaseInfo.trade_no,
        booking_time: `${moment(`${payload.date} ${payload.datetime}`).format('YYYY-MM-DD HH:mm')}`,
        end_time: `${moment(`${payload.end_date} ${payload.end_time}`).format('YYYY-MM-DD HH:mm')}`,
      }))
      console.log('bookingFreeTrial end')
      message.success('預定位子成功！');
    } catch (e) {
      console.log(e)
      // message.error('預定失敗: 3');
    }
  }
  const bookingInfo = getState().reducer.bookingInfo || {};
  // console.log('bookingInfo')
  // console.log(bookingInfo)
  // console.log(!bookingInfo.error)
  if (payload.contactPhone && registerInfo?.user_token && purchaseInfo?.trade_no && !bookingInfo.error) {
    // message.success('已送出成功。請查看你的簡訊，取得免費體驗 QRcode');
    notification.success({
      message: '免費體驗預約成功！',
      description:
        '已傳送入場簡訊至你的手機，請於簡訊內連結開啟入場條碼，掃描入場',
      placement: 'bottomRight',
    });
    // notification.error({
    //   message: '免費體驗申請失敗',
    //   description:
    //     '該手機號碼已申請過體驗，歡迎訂閱享聚落體驗更多空間設備。',
    //   placement: 'bottomRight',
    // });
  }
}

export const fetchZoneList = () => async (dispatch, getState) => {
  // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
  const url = `/api/zone`;
  const { zoneList } = getState().reducer;
  // console.log(`zoneList ${zoneList}`)
  // console.log(`zoneList ${JSON.stringify(getState())}`)
  // if (!zoneList) {
  try {
    const responsePromise = await fetch(url);
    const json = await responsePromise.json();
    console.log(json)
    dispatch({
      type: actionTypes.FETCH_ZONE_DATA_RECEIVED,
      payload: json.data
    });
  } catch (error) {
    console.log(error);
  }
  // }
}

export const setupLoadingStatus = (isLoading) => dispatch({
  type: actionTypes.SET_LOADING_STATUS,
  payload: { isLoading }
});

// export const fetchSearchResult = ({ }) => async (dispatch, getState) => {
//   const url = `https://xarehub.fitbutler.tw?d=web&c=room&m=search`;
//   try {
//     const responsePromise = await fetch(url);
//     const json = await responsePromise.json();
//     console.log(json)
//     dispatch({
//       type: actionTypes.FETCH_SPACE_DATA_RECEIVED,
//       payload: json.data
//     });
//   } catch (error) {
//     console.log(error);
//   }
// }

// export const fetchSearchResult = ({
//   zone_id,
//   date,
//   start_time,
//   end_time,
//   location_no,
//   city_zip,
//   lang = 'cht',
//   ...params
// }) => async (dispatch, getState) => {
//   // const url = `http://xarehub.fitbutler.tw?d=web&c=location&m=getList`;
//   console.log({
//     zone_id,
//     date,
//     start_time,
//     end_time,
//     location_no,
//     city_zip,
//     lang,
//   })
//   const url = toUrlParamsString(`/api/search`, {
//     zone_id,
//     date,
//     start_time,
//     end_time,
//     location_no,
//     city_zip,
//     lang,
//   });
//   const responsePromise = await fetch(url);
//   const json = await responsePromise.json();
//   dispatch({
//     type: actionTypes.FETCH_SPACE_DATA_RECEIVED,
//     payload: json
//   });
//   if (json.error) {
//     console.log(json)
//     message.error(`預訂座位失敗: ${json.message}`);
//     throw new Error(json)
//   }
// }
