import { applyMiddleware, createStore } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import logger from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from './reducer';

export const makeStore = (context) => {
  const middlewares = [thunk];
  if (process.env.NEXT_PUBLIC_ENV !== "production") middlewares.push(logger)
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  return store;
}

export const wrapper = createWrapper(makeStore, { debug: true });
