import moment from 'moment';

export const toUrlParamsString = (uri, obj) => {
  let queryString = '';
  Object.keys(obj).forEach((key) => {
    queryString += `&${key}=${obj[key]}`;
  });
  const reqUrl = `${uri}?${queryString}`;
  return reqUrl;
};

// 限制只有未來可以搜尋：搜尋
export const disabledDate = (current) => current && current < moment().subtract(1, "days")
// 限制只有一個月可以體驗：免費體驗
export const disabledDateOnlyOneMonth = (current) => current && (current < moment().subtract(1, "days") || current > moment().add(1, "month"))
export const disabledDateOnlyOneYear = (current) => current && (current < moment().subtract(1, "days") || current > moment().add(1, "year"))
// 只有7點到23點可以選擇
// export const disabledTime = (current) => moment(current).format('H') >= 7 && moment(current).format('H') < 23
export const disabledTime = () => [0, 1, 2, 3, 4, 5, 6, 23];
// 生日
export const disabledDatefromNow = (current) => current && current > moment().add(0, "days")

export const sendRequest = (url) => new Promise(async (resolve, reject) => {
  try {
    const res = await fetch(url)
    const data = await res.json()
    // console.log(res)
    // console.log(res.status)
    // console.log(res.statusText)
    // console.log(data)
    resolve({ ok: res.ok, status: res.status, statusText: res.statusText, ...data })
  } catch (e) {
    console.log('err', e)
    reject(e)
  }
})
